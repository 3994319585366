$(document).ready(function ($) {

	$('.scrollToTop').click(scrollToTop);
	$('a[href^="/#"].scrollTo').click(scrollTo);


	// Methods
	function scrollToTop() {
		$('html, body').animate({scrollTop: 0}, 600);
		return false;
	}


	function scrollTo(event) {

		event.preventDefault();
		let attr = $.attr(this, 'href');

		try {
			$('html, body').animate({
				scrollTop: $(attr.substring(1, attr.length)).offset().top
			}, 500);

		} catch (e) {
			$(location).attr('href', attr);
		}
	}


});

$(document).ready(function () {
	let url = window.location.href;
	let index = url.indexOf('#') + 1;
	if (index > 0) {
		let hash = url.substring(index);
		let top = $('#' + hash).offset().top;
		let navHeight = $('header.navbar').outerHeight();
		top -= navHeight;
		$('html, body').stop().animate({scrollTop: top}, 200);
	}
});
