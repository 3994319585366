import Swiper from 'swiper/dist/js/swiper.min';

$(document).ready(function () {

	let params = { // eslint-disable-line

		loop: false,
		speed: 500,
		spaceBetween: 20,
		slidesPerView: 3,
		watchOverflow: true,
		autoplay: false,
		simulateTouch: false,
		breakpoints: {
			992: {
				slidesPerView: 1
			}
		},

		pagination: {
			el: '.swiper-pagination',
			clickable: true
		}

	};

	// eslint-disable-next-line no-unused-vars
	let slider =  new Swiper('.multiple-view-slideshow', params);

});
