$(document).ready(function($) {

	let navbar = $('.navbar-shrink');

	$(document).scroll(onScroll).trigger('scroll');
	function onScroll() {

		if($(document).scrollTop() > 100) {
			$(navbar).addClass('shrinked');
		}
		else {
			$(navbar).removeClass('shrinked');
		}

	}

});
